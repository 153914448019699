<template>
  <div id="root">
    <nav class="course">
      <div class="flex items-center h-16 border-gray-200 border-b border-t-0 border-l-0 border-r-0 border-solid">
        <div class="text-xl mr-8 ml-8">培训中心</div>
        <div class="flex items-center flex-1"><span
            class="px-6 hover:text-blue-dark cursor-pointer text-blue-dark">首页</span><span
            class="px-6 hover:text-blue-dark cursor-pointer text-gray-700">视频教学</span><span
            class="px-6 hover:text-blue-dark cursor-pointer text-gray-700">操作指南</span></div>
        <div class="w-96 h-inputHeight flex mr-15">
          <form class="relative border border-gray hover:border-gray-dark" role="search"><input
              class="box-content w-80 h-inputHeight px-3 py-px placeholder-gray-500 placeholder-opacity-75 focus-visible:outline-blue-dark focus-visible:outline-px"
              type="text" placeholder="请输入产品名称或关键词搜索" value="">
            <div
              class="absolute h-inputInner w-8 bg-gray-100 right-1 top-inner cursor-pointer flex justify-center items-center">
              <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2678" width="18"
                height="18" fill="gray" class="align-middle">
                <path
                  d="M966.4 924.8l-230.4-227.2c60.8-67.2 96-156.8 96-256 0-217.6-176-390.4-390.4-390.4-217.6 0-390.4 176-390.4 390.4 0 217.6 176 390.4 390.4 390.4 99.2 0 188.8-35.2 256-96l230.4 227.2c9.6 9.6 28.8 9.6 38.4 0C979.2 950.4 979.2 934.4 966.4 924.8zM102.4 441.6c0-185.6 150.4-339.2 339.2-339.2s339.2 150.4 339.2 339.2c0 89.6-35.2 172.8-92.8 233.6-3.2 0-3.2 3.2-6.4 3.2-3.2 3.2-3.2 3.2-3.2 6.4-60.8 57.6-144 92.8-233.6 92.8C256 780.8 102.4 627.2 102.4 441.6z"
                  p-id="2679"></path>
              </svg></div>
          </form>
        </div>
      </div>
      <div class="w-full block-container">
        <div class="mx-20 mt-8">
          <h1 class="subTitle mb-4">产品</h1>
          <div class="grid grid-cols-2 gap-8 justify-items-stretch">
            <div class="flex items-center border bg-productBackground p-4 rounded cursor-pointer">
              <div class="flex mr-6"><img src="./assets/course/2939023718334490112.png"
                  class="w-20 h-20"></div>
              <div class="flex flex-col">
                <div class="font-bold text-black-0.8 text-lg">API管理平台</div>
                <div class="mt-4 text-gray-500 text-sm">API管理平台的简介</div>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-20 mt-12">
          <h1 class="subTitle mb-4">热门推荐</h1>
          <div class="grid grid-cols-4 gap-6 justify-between">
            <div class="flex items-center rounded"><a
                target="_blank"
                class="block no-underline rounded-xl border-blue-dark border-2 border-solid border-t-0 border-l-0 border-r-0 list-course-card">
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">sextant 接口调试</h3>
                  <div class="text-sm mb-2 text-gray h-imageHeight line-clamp-8">sextant 提供在线调试 HTTP、AS、DC
                    接口的功能，支持直接使用本机地址进行接口调试，另外HTTP支持使用浏览器插件自动获取授权头，使用简单，功能强大。</div>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span></div>
                  <div class="flex justify-end">
                    <div class="text-gray-light text-sm align-middle">操作指南</div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center border rounded"><a class="block rounded-xl list-course-card">
                <div><img
                    src="./assets/course/f43362a084c543fdfd6fea8a7c4b5224.jpg"
                    class="w-full h-imageHeight"></div>
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">导入swagger接口</h3>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">swagger</span></div>
                  <div class="flex justify-between">
                    <div class="text-gray-light text-sm align-middle">01分06秒</div>
                    <div><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2931"
                        width="20" height="20" fill="gray" class="align-middle">
                        <path
                          d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m0 896c-235.64 0-426.666667-191.026667-426.666667-426.666667s191.026667-426.666667 426.666667-426.666667 426.666667 191.026667 426.666667 426.666667-191.026667 426.666667-426.666667 426.666667z m-74.666667-213.38a53.373333 53.373333 0 0 1-53.333333-53.333334V352.066667A53.333333 53.333333 0 0 1 467.373333 308L702 467.933333a53.333333 53.333333 0 0 1 0 88.133334L467.373333 716a53.286667 53.286667 0 0 1-30.04 9.286667z m0.08-383.933334a11.093333 11.093333 0 0 0-5.08 1.28 10.446667 10.446667 0 0 0-5.666666 9.433334v319.866666a10.666667 10.666667 0 0 0 16.666666 8.82l234.666667-159.94a10.666667 10.666667 0 0 0 0-17.626666l-234.666667-159.933334a10.313333 10.313333 0 0 0-5.906666-1.92z"
                          fill="gray" p-id="2932"></path>
                      </svg></div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center border rounded"><a class="block rounded-xl list-course-card">
                <div><img
                    src="./assets/course/c66d01c5e0273d8d5dc47add52f65fe3.jpg"
                    class="w-full h-imageHeight"></div>
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">导入postman接口</h3>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">postman</span></div>
                  <div class="flex justify-between">
                    <div class="text-gray-light text-sm align-middle">01分20秒</div>
                    <div><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2931"
                        width="20" height="20" fill="gray" class="align-middle">
                        <path
                          d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m0 896c-235.64 0-426.666667-191.026667-426.666667-426.666667s191.026667-426.666667 426.666667-426.666667 426.666667 191.026667 426.666667 426.666667-191.026667 426.666667-426.666667 426.666667z m-74.666667-213.38a53.373333 53.373333 0 0 1-53.333333-53.333334V352.066667A53.333333 53.333333 0 0 1 467.373333 308L702 467.933333a53.333333 53.333333 0 0 1 0 88.133334L467.373333 716a53.286667 53.286667 0 0 1-30.04 9.286667z m0.08-383.933334a11.093333 11.093333 0 0 0-5.08 1.28 10.446667 10.446667 0 0 0-5.666666 9.433334v319.866666a10.666667 10.666667 0 0 0 16.666666 8.82l234.666667-159.94a10.666667 10.666667 0 0 0 0-17.626666l-234.666667-159.933334a10.313333 10.313333 0 0 0-5.906666-1.92z"
                          fill="gray" p-id="2932"></path>
                      </svg></div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center rounded"><a
                target="_blank"
                class="block no-underline rounded-xl border-blue-dark border-2 border-solid border-t-0 border-l-0 border-r-0 list-course-card">
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">sextant 功能介绍</h3>
                  <div class="text-sm mb-2 text-gray h-imageHeight line-clamp-8">
                    sextant是一款API管理平台，提供方便的API管理能力，具有强大的mock及接口调试功能</div>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span></div>
                  <div class="flex justify-end">
                    <div class="text-gray-light text-sm align-middle">操作指南</div>
                  </div>
                </div>
              </a></div>
          </div>
        </div>
        <div class="mx-20 mt-12">
          <h1 class="subTitle mb-4">视频教学<span
              class="text-blue-dark float-right text-sm font-normal p-2 cursor-pointer flex flex-row items-center">更多<svg
                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5485" width="12"
                height="12" fill="#1e80ff" class="align-middle">
                <path
                  d="M492.675886 904.817574L885.696074 511.797385 492.675886 118.777197c-12.258185-12.258185-12.432147-32.892131 0.187265-45.51052 12.707416-12.707416 32.995485-12.703323 45.511543-0.187265l411.660734 411.660734c7.120165 7.120165 10.163477 17.065677 8.990768 26.624381 1.500167 9.755178-1.5104 20.010753-8.990768 27.491121L538.374694 950.515359c-12.258185 12.258185-32.892131 12.432147-45.511543-0.187265-12.707416-12.707416-12.703323-32.995485-0.187265-45.51052z"
                  p-id="5486"></path>
              </svg></span></h1>
          <div class="grid grid-cols-4 gap-6 justify-items-stretch">
            <div class="flex items-center border rounded"><a class="block rounded-xl list-course-card">
                <div><img
                    src="./assets/course/f43362a084c543fdfd6fea8a7c4b5224.jpg"
                    class="w-full h-imageHeight"></div>
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">导入swagger接口</h3>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">swagger</span></div>
                  <div class="flex justify-between">
                    <div class="text-gray-light text-sm align-middle">01分06秒</div>
                    <div><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2931"
                        width="20" height="20" fill="gray" class="align-middle">
                        <path
                          d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m0 896c-235.64 0-426.666667-191.026667-426.666667-426.666667s191.026667-426.666667 426.666667-426.666667 426.666667 191.026667 426.666667 426.666667-191.026667 426.666667-426.666667 426.666667z m-74.666667-213.38a53.373333 53.373333 0 0 1-53.333333-53.333334V352.066667A53.333333 53.333333 0 0 1 467.373333 308L702 467.933333a53.333333 53.333333 0 0 1 0 88.133334L467.373333 716a53.286667 53.286667 0 0 1-30.04 9.286667z m0.08-383.933334a11.093333 11.093333 0 0 0-5.08 1.28 10.446667 10.446667 0 0 0-5.666666 9.433334v319.866666a10.666667 10.666667 0 0 0 16.666666 8.82l234.666667-159.94a10.666667 10.666667 0 0 0 0-17.626666l-234.666667-159.933334a10.313333 10.313333 0 0 0-5.906666-1.92z"
                          fill="gray" p-id="2932"></path>
                      </svg></div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center border rounded"><a class="block rounded-xl list-course-card">
                <div><img
                    src="./assets/course/c66d01c5e0273d8d5dc47add52f65fe3.jpg"
                    class="w-full h-imageHeight"></div>
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">导入postman接口</h3>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">postman</span></div>
                  <div class="flex justify-between">
                    <div class="text-gray-light text-sm align-middle">01分20秒</div>
                    <div><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2931"
                        width="20" height="20" fill="gray" class="align-middle">
                        <path
                          d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m0 896c-235.64 0-426.666667-191.026667-426.666667-426.666667s191.026667-426.666667 426.666667-426.666667 426.666667 191.026667 426.666667 426.666667-191.026667 426.666667-426.666667 426.666667z m-74.666667-213.38a53.373333 53.373333 0 0 1-53.333333-53.333334V352.066667A53.333333 53.333333 0 0 1 467.373333 308L702 467.933333a53.333333 53.333333 0 0 1 0 88.133334L467.373333 716a53.286667 53.286667 0 0 1-30.04 9.286667z m0.08-383.933334a11.093333 11.093333 0 0 0-5.08 1.28 10.446667 10.446667 0 0 0-5.666666 9.433334v319.866666a10.666667 10.666667 0 0 0 16.666666 8.82l234.666667-159.94a10.666667 10.666667 0 0 0 0-17.626666l-234.666667-159.933334a10.313333 10.313333 0 0 0-5.906666-1.92z"
                          fill="gray" p-id="2932"></path>
                      </svg></div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center border rounded"><a class="block rounded-xl list-course-card">
                <div><img
                    src="./assets/course/dc1e20b9803721dfd8f54773f092c161.jpg"
                    class="w-full h-imageHeight"></div>
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">导入yapi接口到sextant</h3>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">yapi</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span></div>
                  <div class="flex justify-between">
                    <div class="text-gray-light text-sm align-middle">01分12秒</div>
                    <div><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2931"
                        width="20" height="20" fill="gray" class="align-middle">
                        <path
                          d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m0 896c-235.64 0-426.666667-191.026667-426.666667-426.666667s191.026667-426.666667 426.666667-426.666667 426.666667 191.026667 426.666667 426.666667-191.026667 426.666667-426.666667 426.666667z m-74.666667-213.38a53.373333 53.373333 0 0 1-53.333333-53.333334V352.066667A53.333333 53.333333 0 0 1 467.373333 308L702 467.933333a53.333333 53.333333 0 0 1 0 88.133334L467.373333 716a53.286667 53.286667 0 0 1-30.04 9.286667z m0.08-383.933334a11.093333 11.093333 0 0 0-5.08 1.28 10.446667 10.446667 0 0 0-5.666666 9.433334v319.866666a10.666667 10.666667 0 0 0 16.666666 8.82l234.666667-159.94a10.666667 10.666667 0 0 0 0-17.626666l-234.666667-159.933334a10.313333 10.313333 0 0 0-5.906666-1.92z"
                          fill="gray" p-id="2932"></path>
                      </svg></div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center border rounded"><a class="block rounded-xl list-course-card">
                <div><img
                    src="./assets/course/12310c536d9da4d383ee58eb43ef3d42.jpg"
                    class="w-full h-imageHeight"></div>
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">IDEA插件导出接口到Sextant</h3>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">IDEA插件</span></div>
                  <div class="flex justify-between">
                    <div class="text-gray-light text-sm align-middle">03分38秒</div>
                    <div><svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2931"
                        width="20" height="20" fill="gray" class="align-middle">
                        <path
                          d="M512 42.666667C252.793333 42.666667 42.666667 252.793333 42.666667 512s210.126667 469.333333 469.333333 469.333333 469.333333-210.126667 469.333333-469.333333S771.206667 42.666667 512 42.666667z m0 896c-235.64 0-426.666667-191.026667-426.666667-426.666667s191.026667-426.666667 426.666667-426.666667 426.666667 191.026667 426.666667 426.666667-191.026667 426.666667-426.666667 426.666667z m-74.666667-213.38a53.373333 53.373333 0 0 1-53.333333-53.333334V352.066667A53.333333 53.333333 0 0 1 467.373333 308L702 467.933333a53.333333 53.333333 0 0 1 0 88.133334L467.373333 716a53.286667 53.286667 0 0 1-30.04 9.286667z m0.08-383.933334a11.093333 11.093333 0 0 0-5.08 1.28 10.446667 10.446667 0 0 0-5.666666 9.433334v319.866666a10.666667 10.666667 0 0 0 16.666666 8.82l234.666667-159.94a10.666667 10.666667 0 0 0 0-17.626666l-234.666667-159.933334a10.313333 10.313333 0 0 0-5.906666-1.92z"
                          fill="gray" p-id="2932"></path>
                      </svg></div>
                  </div>
                </div>
              </a></div>
          </div>
        </div>
        <div class="mx-20 mt-12 mb-32">
          <h1 class="subTitle mb-4">操作指南<span
              class="text-blue-dark float-right text-sm font-normal p-2 cursor-pointer flex flex-row items-center">更多<svg
                viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5485" width="12"
                height="12" fill="#1e80ff" class="align-middle">
                <path
                  d="M492.675886 904.817574L885.696074 511.797385 492.675886 118.777197c-12.258185-12.258185-12.432147-32.892131 0.187265-45.51052 12.707416-12.707416 32.995485-12.703323 45.511543-0.187265l411.660734 411.660734c7.120165 7.120165 10.163477 17.065677 8.990768 26.624381 1.500167 9.755178-1.5104 20.010753-8.990768 27.491121L538.374694 950.515359c-12.258185 12.258185-32.892131 12.432147-45.511543-0.187265-12.707416-12.707416-12.703323-32.995485-0.187265-45.51052z"
                  p-id="5486"></path>
              </svg></span></h1>
          <div class="grid grid-cols-4 gap-6 justify-items-stretch">
            <div class="flex items-center rounded"><a
                target="_blank"
                class="block no-underline rounded-xl border-blue-dark border-2 border-solid border-t-0 border-l-0 border-r-0 list-course-card">
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">sextant 接口调试</h3>
                  <div class="text-sm mb-2 text-gray h-imageHeight line-clamp-8">sextant 提供在线调试 HTTP、AS、DC
                    接口的功能，支持直接使用本机地址进行接口调试，另外HTTP支持使用浏览器插件自动获取授权头，使用简单，功能强大。</div>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span></div>
                  <div class="flex justify-end">
                    <div class="text-gray-light text-sm align-middle">操作指南</div>
                  </div>
                </div>
              </a></div>
            <div class="flex items-center rounded"><a
                target="_blank"
                class="block no-underline rounded-xl border-blue-dark border-2 border-solid border-t-0 border-l-0 border-r-0 list-course-card">
                <div class="relative pt-2 pb-4 px-3">
                  <h3 class="line-clamp-2 mt-1 mb-2 font-medium card-title text-black-0.8">sextant 功能介绍</h3>
                  <div class="text-sm mb-2 text-gray h-imageHeight line-clamp-8">
                    sextant是一款API管理平台，提供方便的API管理能力，具有强大的mock及接口调试功能</div>
                  <div class="text-sm mb-2 text-blue-dark">API管理平台</div>
                  <div class="flex flex-wrap mb-4"><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">sextant</span><span
                      class="inline-block mr-3 px-2 py-1 bg-blue-dark text-white text-xs rounded">api管理平台</span></div>
                  <div class="flex justify-end">
                    <div class="text-gray-light text-sm align-middle">操作指南</div>
                  </div>
                </div>
              </a></div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<style>
@import './assets/course/style.css';
@import './assets/course/style\(1\).css';
body,
html {
  font-size: 16px!important;
}
</style>

<style>
.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.right-1 {
  right: 0.25rem;
}

.top-inner {
  top: 3px;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.top-1 {
  top: 0.25rem;
}

.float-right {
  float: right;
}

.mx-20 {
  margin-left: 5rem;
  margin-right: 5rem;
}

.my-36 {
  margin-top: 9rem;
  margin-bottom: 9rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-28 {
  margin-left: 7rem;
  margin-right: 7rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.mr-8 {
  margin-right: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mt-0 {
  margin-top: 0px;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-32 {
  margin-bottom: 8rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mt-28 {
  margin-top: 7rem;
}

.mb-20 {
  margin-bottom: 5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.box-border {
  box-sizing: border-box;
}

.box-content {
  box-sizing: content-box;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.h-16 {
  height: 4rem;
}

.h-inputHeight {
  height: 30px;
}

.h-inputInner {
  height: 30px;
}

.h-imageHeight {
  height: 158px;
}

.h-48 {
  height: 12rem;
}

.h-screen {
  height: 100vh;
}

.h-20 {
  height: 5rem;
}

.h-14 {
  height: 3.5rem;
}

.h-10 {
  height: 2.5rem;
}

.h-6 {
  height: 1.5rem;
}

.h-8 {
  height: 2rem;
}

.h-7 {
  height: 1.75rem;
}

.w-96 {
  width: 24rem;
}

.w-80 {
  width: 20rem;
}

.w-8 {
  width: 2rem;
}

.w-full {
  width: 100%;
}

.w-20 {
  width: 5rem;
}

.w-14 {
  width: 3.5rem;
}

.flex-1 {
  flex: 1 1 0%;
}

.flex-auto {
  flex: 1 1 auto;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.resize {
  resize: both;
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-items-stretch {
  justify-items: stretch;
}

.gap-6 {
  gap: 1.5rem;
}

.gap-8 {
  gap: 2rem;
}

.overflow-auto {
  overflow: auto;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-sm {
  border-radius: 0.125rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.border {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-0 {
  border-width: 0px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-b-2 {
  border-bottom-width: 2px;
}

.border-solid {
  border-style: solid;
}

.border-dashed {
  border-style: dashed;
}

.border-none {
  border-style: none;
}

.border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(229 231 235 / var(--tw-border-opacity));
}

.border-gray {
  --tw-border-opacity: 1;
  border-color: rgb(194 200 209 / var(--tw-border-opacity));
}

.border-blue-dark {
  --tw-border-opacity: 1;
  border-color: rgb(41 93 250 / var(--tw-border-opacity));
}

.border-black-0\.1 {
  border-color: rgba(0, 0, 0, 0.1);
}

.bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.bg-blue-50 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity));
}

.bg-blue-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(41 93 250 / var(--tw-bg-opacity));
}

.bg-productBackground {
  background-color: rgba(0, 0, 0, .03);
}

.bg-gray-lightest {
  --tw-bg-opacity: 1;
  background-color: rgb(242 243 245 / var(--tw-bg-opacity));
}

.bg-blueDisabled {
  --tw-bg-opacity: 1;
  background-color: rgb(171 205 255 / var(--tw-bg-opacity));
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-2 {
  padding: 0.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.text-center {
  text-align: center;
}

.align-middle {
  vertical-align: middle;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: 14px;
}

.text-xs {
  font-size: 12px;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-lg {
  font-size: 16px;
}

.font-medium {
  font-weight: 500;
}

.font-normal {
  font-weight: 400;
}

.font-bold {
  font-weight: 700;
}

.text-blue-dark {
  --tw-text-opacity: 1;
  color: rgb(41 93 250 / var(--tw-text-opacity));
}

.text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity));
}

.text-black-0\.8 {
  color: rgba(0, 0, 0, 0.8);
}

.text-gray {
  --tw-text-opacity: 1;
  color: rgb(194 200 209 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-gray-light {
  --tw-text-opacity: 1;
  color: rgb(161 169 178 / var(--tw-text-opacity));
}

.text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.text-black-1 {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-black-0\.1 {
  color: rgba(0, 0, 0, 0.1);
}

.text-primary {
  color: var(--vc-color-primary);
}

.no-underline {
  text-decoration-line: none;
}

.placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity));
}

.placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75;
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-8 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

body,
html {
  background: #fff;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
  padding: 0;
}

.block-container {
  width: 1280px;
  margin: 0 auto;
}

.subTitle:before {
  content: "";
  display: inline-block;
  position: relative;
  top: -0.1em;
  vertical-align: middle;
  height: calc(4px + 0.7em);
  margin-right: calc(5px + 0.2em);
  border-left: calc(5px + 0.1em) solid #295dfa;
}

.outline-width:focus-visible {
  outline: 1px solid #295dfa;
}

.circular {
  width: 60px;
  height: 60px;
  animation: rotate 2s linear infinite;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  stroke: #1e80ff;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.hover\:border-gray-dark:hover {
  --tw-border-opacity: 1;
  border-color: rgb(138 145 159 / var(--tw-border-opacity));
}

.hover\:text-blue-dark:hover {
  --tw-text-opacity: 1;
  color: rgb(41 93 250 / var(--tw-text-opacity));
}

.hover\:text-primary:hover {
  color: var(--vc-color-primary);
}

.hover\:text-black-0\.1:hover {
  color: rgba(0, 0, 0, 0.1);
}

.focus-visible\:bg-white:focus-visible {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.focus-visible\:outline:focus-visible {
  outline-style: solid;
}

.focus-visible\:outline-blue-dark:focus-visible {
  outline-color: #295dfa;
}

.focus-visible\:outline-blue-light:focus-visible {
  outline-color: #1e80ff;
}
</style>
<style>
.rc-notification {
  position: fixed;
  z-index: 1000;
  display: flex;
  max-height: 100vh;
  padding: 10px;
  overflow-y: auto;
  align-items: flex-end;
}

.rc-notification-top,
.rc-notification-topLeft,
.rc-notification-topRight {
  top: 200px;
  flex-direction: column;
}

.rc-notification-topRight {
  left: 50%;
  transform: translateX(-50%);
}

.rc-notification-notice {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: auto;
  margin: 12px 0;
  line-height: 1.5;
  background: #fff;
  border: 1px solid #999;
  border: 0px solid rgba(0, 0, 0, 0);
  border-radius: 3px 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.rc-notification-notice-content {
  padding: 7px 20px 7px 10px;
}

.rc-notification-notice-closable .rc-notification-notice-content {
  padding-right: 20px;
}

.rc-notification-notice-close {
  position: absolute;
  top: 3px;
  right: 5px;
  color: #000;
  font-weight: 700;
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  text-shadow: 0 1px 0 #fff;
  outline: none;
  cursor: pointer;
  opacity: 0.2;
  filter: alpha(opacity=20);
}

.rc-notification-notice-close-x:after {
  content: "×";
}

.rc-notification-notice-close:hover {
  text-decoration: none;
  opacity: 1;
  filter: alpha(opacity=100);
}

.rc-notification-fade {
  overflow: hidden;
  transition: all 0.3s;
}

.rc-notification-fade-appear-start {
  transform: translateX(100%);
  opacity: 0;
}

.rc-notification-fade-appear-active {
  transform: translateX(0);
  opacity: 1;
}
</style>
<style>
.rc-message-content .text {
  font-size: 16px;
  margin-top: 10px;
}

.rc-message-content .icon svg,
.rc-message-content .icon i {
  font-size: 32px;
}
</style>
<style>
.list-course-card {
  display: block;
  width: 280px;
  height: 334px;
  background: #fff;
  box-shadow: 0 4px 30px rgba(0, 80, 179, 0.08);
  border-radius: 8px;
  font-family: PingFang SC, sans-serif;
  outline: none;
  transition: box-shadow 0.3s;
  overflow: hidden;
  cursor: pointer;
  font-size: 16px;
}

.list-course-card .card-title {
  height: 48px;
  line-height: 24px;
}

.list-course-card img {
  width: 280px;
  object-fit: cover;
}

.h-description {
  height: 153px;
}
</style>
<style>
.header {
  /* background-image: url("/src/assets/images/txrpic.jpg"); */
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 设置背景颜色，背景图加载过程中会显示背景色 */
}

.header .title {
  line-height: 12rem;
}
</style>
<style>
.rc-pagination {
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.rc-pagination ul,
.rc-pagination ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.rc-pagination::after {
  display: block;
  clear: both;
  height: 0;
  overflow: hidden;
  visibility: hidden;
  content: ' ';
}

.rc-pagination-total-text {
  display: inline-block;
  height: 28px;
  margin-right: 8px;
  line-height: 26px;
  vertical-align: middle;
}

.rc-pagination-item {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  margin-right: 8px;
  font-family: Arial;
  line-height: 26px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: 0;
  cursor: pointer;
  user-select: none;
}

.rc-pagination-item a {
  display: block;
  padding: 0 6px;
  color: rgba(0, 0, 0, 0.85);
  transition: none;
}

.rc-pagination-item a:hover {
  text-decoration: none;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  border-color: #1890ff;
  transition: all 0.3s;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: #1890ff;
}

.rc-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: #1890ff;
}

.rc-pagination-item-active a {
  color: #1890ff;
}

.rc-pagination-item-active:focus,
.rc-pagination-item-active:hover {
  border-color: #40a9ff;
}

.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
  color: #40a9ff;
}

.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  outline: 0;
}

.rc-pagination-jump-prev button,
.rc-pagination-jump-next button {
  background: transparent;
  border: none;
  cursor: pointer;
  color: #666;
}

.rc-pagination-jump-prev button:after,
.rc-pagination-jump-next button:after {
  display: block;
  content: '•••';
}

.rc-pagination-prev,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  margin-right: 8px;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-prev,
.rc-pagination-jump-next {
  display: inline-block;
  min-width: 28px;
  height: 28px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Arial;
  line-height: 28px;
  text-align: center;
  vertical-align: middle;
  list-style: none;
  border-radius: 2px;
  cursor: pointer;
  transition: all 0.3s;
}

.rc-pagination-prev,
.rc-pagination-next {
  outline: 0;
}

.rc-pagination-prev button,
.rc-pagination-next button {
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  user-select: none;
}

.rc-pagination-prev:hover button,
.rc-pagination-next:hover button {
  border-color: #40a9ff;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 12px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: all 0.3s;
}

.rc-pagination-prev:focus .rc-pagination-item-link,
.rc-pagination-next:focus .rc-pagination-item-link,
.rc-pagination-prev:hover .rc-pagination-item-link,
.rc-pagination-next:hover .rc-pagination-item-link {
  color: #1890ff;
  border-color: #1890ff;
}

.rc-pagination-prev button:after {
  content: '‹';
  display: block;
}

.rc-pagination-next button:after {
  content: '›';
  display: block;
}

.rc-pagination-disabled,
.rc-pagination-disabled:hover,
.rc-pagination-disabled:focus {
  cursor: not-allowed;
}

.rc-pagination-disabled .rc-pagination-item-link,
.rc-pagination-disabled:hover .rc-pagination-item-link,
.rc-pagination-disabled:focus .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination-slash {
  margin: 0 10px 0 5px;
}

.rc-pagination-options {
  display: inline-block;
  margin-left: 16px;
  vertical-align: middle;
}

@media all and (-ms-high-contrast: none) {

  .rc-pagination-options *::-ms-backdrop,
  .rc-pagination-options {
    vertical-align: top;
  }
}

.rc-pagination-options-size-changer.rc-select {
  display: inline-block;
  width: auto;
  margin-right: 8px;
}

.rc-pagination-options-quick-jumper {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}

.rc-pagination-options-quick-jumper input {
  width: 50px;
  margin: 0 8px;
}

.rc-pagination-simple .rc-pagination-prev,
.rc-pagination-simple .rc-pagination-next {
  height: 24px;
  line-height: 24px;
  vertical-align: top;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link {
  height: 24px;
  background-color: transparent;
  border: 0;
}

.rc-pagination-simple .rc-pagination-prev .rc-pagination-item-link::after,
.rc-pagination-simple .rc-pagination-next .rc-pagination-item-link::after {
  height: 24px;
  line-height: 24px;
}

.rc-pagination-simple .rc-pagination-simple-pager {
  display: inline-block;
  height: 24px;
  margin-right: 8px;
}

.rc-pagination-simple .rc-pagination-simple-pager input {
  box-sizing: border-box;
  height: 100%;
  margin-right: 8px;
  padding: 0 6px;
  text-align: center;
  background-color: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  outline: none;
  transition: border-color 0.3s;
}

.rc-pagination-simple .rc-pagination-simple-pager input:hover {
  border-color: #1890ff;
}

.rc-pagination.rc-pagination-disabled {
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item {
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item a {
  color: rgba(0, 0, 0, 0.25);
  background: transparent;
  border: none;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active {
  background: #dbdbdb;
  border-color: transparent;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-active a {
  color: #fff;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link {
  color: rgba(0, 0, 0, 0.25);
  background: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-link-icon {
  opacity: 0;
}

.rc-pagination.rc-pagination-disabled .rc-pagination-item-ellipsis {
  opacity: 1;
}

@media only screen and (max-width: 992px) {

  .rc-pagination-item-after-jump-prev,
  .rc-pagination-item-before-jump-next {
    display: none;
  }
}

@media only screen and (max-width: 576px) {
  .rc-pagination-options {
    display: none;
  }
}
</style>
<style>
.rc-pagination-item-active a {
  color: #295dfa;
}

.rc-pagination-item-active,
.rc-pagination-item {
  line-height: 28px;
  border-color: #295dfa;
  vertical-align: middle;
  border: none;
}

.rc-pagination-item-active:focus a,
.rc-pagination-item-active:hover a {
  color: #295dfa;
}

.rc-pagination-item:focus,
.rc-pagination-item:hover {
  border-color: #295dfa;
  border: none;
  transition: all 0.3s;
}

.rc-pagination-item:focus a,
.rc-pagination-item:hover a {
  color: #295dfa;
}

.rc-pagination-options-quick-jumper input {
  height: 24px;
  padding-left: 10px;
  text-align: center;
  outline: none;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.rc-select {
  position: relative;
  margin: 0 28px;
  width: 66px !important;
  height: 28px;
  border-radius: 3px;
  border: solid 1px #d9d9d9;
}

.rc-select .rc-select-selection-search {
  position: relative;
}

.rc-select .rc-select-selection-search .rc-select-selection-search-input {
  width: 100%;
  height: 100%;
}

.rc-select .rc-select-selector .rc-select-selection-item {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}

.rc-select .rc-select-dropdown {
  padding: 2px 6px;
  background: #fff;
  border: 1px solid #d9d9d9;
  min-width: 100px !important;
  border-radius: 3px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.rc-select .rc-select-dropdown .rc-select-item {
  position: relative;
  cursor: pointer;
}

.rc-select .rc-select-dropdown .rc-select-item .rc-select-item-option-content {
  line-height: 28px;
}

.rc-select .rc-select-dropdown .rc-select-item .rc-select-item-option-state {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
}

.rc-select .rc-select-dropdown .rc-select-item[aria-selected=true] {
  color: #295dfa;
}

.rc-select .rc-select-dropdown-hidden {
  display: none;
}

.rc-pagination-prev,
.rc-pagination-next,
.rc-pagination-jump-next,
.rc-pagination-jump-prev {
  cursor: pointer;
}

.rc-pagination-prev .rc-pagination-item-link,
.rc-pagination-next .rc-pagination-item-link,
.rc-pagination-jump-next .rc-pagination-item-link,
.rc-pagination-jump-prev .rc-pagination-item-link {
  border: none;
  background: none;
}

.rc-pagination-prev .rc-pagination-item-link:after,
.rc-pagination-next .rc-pagination-item-link:after,
.rc-pagination-jump-next .rc-pagination-item-link:after,
.rc-pagination-jump-prev .rc-pagination-item-link:after {
  font-size: 20px;
  color: #606266;
}

.rc-pagination-disabled .rc-pagination-item-link:after {
  color: #c0c4cc;
}

.rc-pagination-prev .rc-pagination-item-link {
  margin-right: 23px;
}

.rc-pagination-prev .rc-pagination-item-link:after {
  content: "‹";
}

.rc-pagination-next .rc-pagination-item-link {
  margin-left: 8px;
}

.rc-pagination-next .rc-pagination-item-link:after {
  content: "›";
}

.rc-pagination-jump-next .rc-pagination-item-link,
.rc-pagination-jump-prev .rc-pagination-item-link {
  cursor: pointer;
}

.rc-pagination-jump-next .rc-pagination-item-link:after,
.rc-pagination-jump-prev .rc-pagination-item-link:after {
  display: block;
  content: "...";
}

.rc-pagination-item {
  cursor: pointer;
}

.rc-pagination-item-active {
  color: var(--vc-primary-color);
}
</style>

<script>
import config from '../../configCenter.json'
export default {
  mounted () {
    document.title = config.title || config.companyFullName
  }
}
</script>